import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ReactGA from "react-ga";
import DonationFooter from "../../components/donation-footer";
import { Link } from "gatsby";
import AdBanner from "../../components/ad-banner";

class View extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: ""
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		let textToProcess = event.target.value;
		textToProcess = textToProcess.replace(/[\n\s\t]+/g, " ").trim();

		this.setState({ value: textToProcess }, () => {
			console.log("Done");
		});
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<Layout>
				<SEO
					title="Remove Tabs, Spaces, Newlines and Linebreaks"
					keywords={[`recommeded products`]}
				/>
				{/* <Navigation /> */}
				<div style={{ marginTop: 30 }} />
				{/* <AdBanner></AdBanner> */}
				<div style={{ marginTop: 30 }} />
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h3
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								<Link to="/products">{"⬅ Remove Tabs, Spaces, Newlines and Linebreaks"}</Link>
							</h3>
						</Col>
					</Row>
				</Container>
				<Container
					style={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<div
						className="card-2"
						style={{
							padding: "1rem",
							backgroundColor: "white",
							width: "100%",
							maxWidth: 650
						}}
					>
						<Form>
							<Form.Group>
								<Form.Label>Paste text here.</Form.Label>
								<Form.Control
									id="textarea"
									as="textarea"
									rows="5"
									value={this.state.value}
									onChange={this.handleChange}
								/>
							</Form.Group>
						</Form>
					</div>
				</Container>
				<div style={{ marginTop: 50 }} />
				<Container>
                    <DonationFooter ad={false} />
				</Container>
			</Layout>
		);
	}
}

export default View;
